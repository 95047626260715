<template>
  <b-sidebar right v-model="show" class="sidebar-search-new-version" shadow backdrop @change="hasClosedSidebar" ref="sidebar_search">
    <template #header>
      <div class="w-100 d-flex justify-content-between align-items-center px-1">
        <h5 class="m-0">{{ $t('search_preview.profileInfluencer') }}</h5>
        <feather-icon icon="XIcon" class="cursor-pointer" size="20" @click="show = false"></feather-icon>
      </div>
    </template>
    <b-row class="m-0 overflow-none" v-if="loaded_service">

      <!-- Left data of sidebar, avatar and add campaign actions -->
      <b-col class="col-4 px-2 border-bottom-sidebar" v-if="data_user && data_user.parent_data">
        <div class="d-flex flex-column align-items-center w-100">
          <b-avatar
            :src="getAvatar()"
            class="p-0 mb-2 avatar-sidebar-search"
          ></b-avatar>
          <h4 class="text-capitalize text-underline cursor-pointer">{{ data_user.parent_data.username.name.fullname }}</h4>
          <p class="text-muted-left-side-analytics">{{ getFormat(data_user.parent_data.followers)}} {{ $t('search_preview.totalAudience') }}</p>
          <p class="text-muted-left-side-analytics mb-1" v-if="data_user.features && data_user.features.blogger_geo && data_user.features.blogger_geo.data">{{ data_user.features.blogger_geo.data.country }}</p>
        
          <add-campaign-and-list 
            :data="{username: data_user.basic.username, network: 'instagram', followers: data_user.parent_data.followers, image: network === 'instagram' ? data_user.parent_data.username.name.ava : data_user.parent_data.username.name.avatar}"
            :lists_dad="lists"
            :favorite_list_dad="favorite_list"
            @update_favorite_list="updateFavoriteList"  
          ></add-campaign-and-list>
        </div>
      </b-col>

      <!-- Instagram - Tiktok - Youtube template right data -->
      <b-col class="col-8 background-small p-0" v-if="data_user && !error_data && (network === 'instagram' || network === 'youtube')">
        <div class="box-expired-report" v-if="has_expired">
          <h5>{{ $t('search_preview.reportExpired') }}</h5>
          <p class="avenir-medium">{{ $t('search_preview.accessFor') }}  {{ $t('preview.days') }}</p>
          <b-button class="blue-button" variant="blue-button" @click.once="getProfileUrl()">{{ $t('lists.update') }}</b-button>
        </div>
        <div class="p-1">

          <content-profile-analytics 
            :data_user="data_user" 
            :network="network"
            :is_paid="is_paid"
            :has_expired="has_expired"
            :subscribers_growth_prc_graph="subscribers_growth_prc_graph"
            :mentions_avatares="mentions_avatares"
            :mentions_user="mentions_user"
            @get_profile_url="unlockReport"
          ></content-profile-analytics>
  
        </div>
      </b-col>

      <!-- Twitter template right data -->
      <b-col class="col-8 background-small p-0" v-if="data_user && !error_data && network === 'twitter'">
        <div class="p-1">
          <content-profile-analytics-twitter
            :data_user="data_user" 
            :is_paid="is_paid"
            :network="network"
            @get_profile_url="unlockReport"
          />
        </div>
        
      </b-col>

      <!-- Twitch template right data -->
      <b-col class="col-8 background-small p-0" v-if="data_user && !error_data && network === 'twitch'">
        <content-profile-analytics-twitch
          :is_paid="is_paid"
          :data_user="data_user" 
          :network="network"
          @get_profile_url="unlockReport"
          >
        </content-profile-analytics-twitch>
      </b-col>


            <!-- Twitch template right data -->
      <b-col class="col-8 background-small p-0" v-if="data_user && !error_data && network === 'tiktok'">
        <content-profile-analytics-tiktok
            :data_user="data_user"
            :network="network"
            :is_paid="is_paid"
            :has_expired="has_expired"
            :subscribers_growth_prc_graph="subscribers_growth_prc_graph"
            :mentions_avatares="mentions_avatares"
            :mentions_user="mentions_user"
            @get_profile_url="unlockReport"
          ></content-profile-analytics-tiktok>
      </b-col>

      <!-- Error data of getting data -->
      <b-col v-else-if="error_data" class="col-8 background-small py-1">
        <div class="d-flex block-emtpy-data align-items-center">
          <div class="container-icon-lock">
            <feather-icon icon="LockIcon" class="icon-lock"></feather-icon>
          </div>
          <h5 class="m-0 text-center w-100">{{ $t('search_preview.accountDenied') }}</h5>
        </div>
      </b-col>
    </b-row>

    <!-- Skeleton for simulating loading page -->
    <b-row v-else class="m-0 overflow-none">
      <b-col class="col-4 px-2 border-bottom-sidebar">
        <div class="d-flex flex-column align-items-center">
          <b-skeleton class="avatar-sidebar-search"></b-skeleton>
          <b-skeleton class="col-9 mb-02"></b-skeleton>
          <b-skeleton class="col-6 mb-02"></b-skeleton>
          <b-skeleton class="col-12 mt-2 p-1"></b-skeleton>
        </div>
      </b-col>

      <b-col class="col-8 background-small py-1">
        <div class="grid-container mb-2 min-height-281">
          <div class="grid-item" v-for="(index) in 5" :key="index">
            <b-skeleton class="w-100 h-100"></b-skeleton>
          </div>
        </div>

        <div class="grid-analytics mb-2">
          <div class="grid-item min-height-116" v-for="(index) in 4" :key="index">
            <b-skeleton class="w-100 h-100"></b-skeleton>
          </div>
        </div>

        <div class="mb-2 min-height-321">
          <b-skeleton class="w-100 h-100"></b-skeleton>
        </div>

        <div class="mb-2 min-height-232">
          <b-skeleton class="w-100 h-100"></b-skeleton>
        </div>
      </b-col>

    </b-row>
  </b-sidebar>
</template>

<script>
import { 
  BSidebar, 
  BRow, 
  BCol,
  BAvatar,
  BButton,
  BSkeleton,
} from "bootstrap-vue";
import { getSummaryInfluencer, getHistorySuscribers, getInfluencerIn, getSummaryInfluencerTiktok, getHistorySuscribersLaravel, unlockReport } from '@/services/search';
import { getFormat, checkNumber, separatebycomma } from "@/libs/utils/formats";
import { getCategoryIconSearcher, getIconInterest } from "@/libs/utils/icons";
import { hasPermission } from "@/libs/utils/user";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: "SidebarSearchNew",
  components: {
    BSidebar,
    BRow,
    BCol,
    BButton,
    BSkeleton,
    BAvatar,
    AddCampaignAndList: () => import('@/views/components/dropdowns/addCampaignAndList.vue'),
    ContentProfileAnalytics: () => import('@/views/components/sidebar/ContentProfileAnalytics.vue'),
    ContentProfileAnalyticsTwitch: () => import('@/views/components/sidebar/twitch/ContentProfileAnalyticsTwitch.vue'),
    ContentProfileAnalyticsTwitter: () => import('@/views/components/sidebar/twitter/ContentProfileAnalyticsTwitter.vue'),
    ContentProfileAnalyticsTiktok: () => import('@/views/components/sidebar/tiktok/ContentProfileAnalyticsTiktok.vue')
  },
  props: {
    open_sidebar: {
      type: Boolean
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
    network: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      getFormat,
      checkNumber,
      getCategoryIconSearcher,
      getIconInterest,
      separatebycomma,
      show: this.open_sidebar,
      lists: [],
      campaigns: [],
      backgroundDefault: require("@/assets/images/dashboard/imgDefault.png"),
      update_image: false,
      data_user: null, 
      error_data: null,
      subscribers_growth_prc_graph: null,
      mentions_user: null,
      mentions_avatares: [],
      loaded_service: false,
      loaded_service_add: false,
      favorite_list: { with_influencer: false},   
      local_data: { ...this.data },
      is_paid: false,
      has_expired: false,
    }
  },
  created() {
    this.getData();
  },
  computed: {
    host() {
      return window.location.host;
    }
  },
  methods: {
    getAvatar() {
      if (this.network === 'instagram') {
        return this.data_user.parent_data.username.name?.ava;
      }
      return this.data_user.parent_data.username.name?.avatar || this.data_user.parent_data.username.avatar;
    },
    mentionsCase(post_frequency) {
      let traduction = '';
      switch (true) {
      case post_frequency > 21:
        traduction = 'mentions_text_mark.frequency'
        break;
      case post_frequency > 14:
        traduction = 'mentions_text_mark.optimal_above'
        break;
      case post_frequency > 6:
        traduction = 'mentions_text_mark.optimal'
        break;
      case post_frequency > 4:
        traduction = 'mentions_text_mark.below_optimal'
        break;
      default:
        traduction = 'mentions_text_mark.not_frequency'
        break;
      }
      return traduction;
    },

    // to contentProfile component 
    // getImagesContent() {
    //   if (this.data_user.features.last_media?.data && Object.keys(this.data_user.features.last_media.data).length > 0) {
    //     const array_images = []
    //     for (const image_objet in this.data_user.features.last_media.data) {
    //       array_images.push(this.data_user.features.last_media.data[image_objet])
    //     }
    //     return array_images.slice(0, 5);
    //   }
    //   return []
    // },
    // 
    getData() {
      const username = this.data.username.name.username;

      if (this.network === 'tiktok' || this.network === 'youtube') {
        getSummaryInfluencerTiktok(this.network, username).then((response) => {
          if (response.error) {
            this.error_data = response.error;
            this.data_user = {};
            return;
          }

          this.data_user = response.report;
          this.is_paid = response.is_paid
          this.has_expired = response.is_expired
          const blogger_mentions = this.data_user?.features?.blogger_mentions?.data || null;

          // Verificamos si existen menciones válidas
          if (
            blogger_mentions &&
            blogger_mentions.mentions &&
            blogger_mentions.mentions.performance &&
            blogger_mentions.mentions.performance["180d"] &&
            Array.isArray(blogger_mentions.mentions.performance["180d"].data) &&
            blogger_mentions.mentions.performance["180d"].data.length > 0
          ) {
            this.mentions_user = blogger_mentions.mentions.performance["180d"];
          } else {
            this.mentions_user = null;
          }

          // Construcción de avatares solo si hay menciones válidas
          this.mentions_avatares = [];
          if (this.mentions_user && blogger_mentions.users) {
            for (const user in blogger_mentions.users) {
              if (blogger_mentions.users[user]?.avatar_url) {
                this.mentions_avatares.push(blogger_mentions.users[user].avatar_url);
              }
            }
            this.mentions_avatares = this.mentions_avatares.slice(0, blogger_mentions.mentions.performance["180d"].data.length);
          }

          if (this.local_data?.categories?.all_categories.length > 0) {
            const first_element = this.local_data.categories.all_categories[0];

            if (typeof first_element === 'object') {
              const IDs = this.network !== 'youtube' ? this.data_user.features?.blogger_labels?.data?.slice(0, 5) || [] : this.data_user.features?.blogger_thematics?.data?.slice(0, 5) || [];

              const categories = [];

              IDs.forEach(category => {
                const exists = this.local_data.categories.all_categories.find((cat => cat.id === category));
                if (exists) {
                  categories.push(exists.title);
                }
              });

              this.local_data = {
                ...this.local_data, 
                categories: {
                  all_categories: categories // Solo los títulos filtrados
                }
              };
            }
          }

          this.data_user.parent_data = this.local_data;
          this.loaded_service = true;

        });
      } else {
        getSummaryInfluencer(this.network, username).then((response) => {

          if (response.error) {
            this.error_data = response.error;
            this.data_user = {};
            
          } else if (this.network === 'instagram') {
            this.data_user = response.report;
            this.is_paid = response.is_paid
            this.has_expired = response.is_expired

            if (this.local_data?.categories?.all_categories.length > 0) {
              const first_element = this.local_data.categories.all_categories[0];

              if (typeof first_element === 'object') {
                const IDs = this.network !== 'youtube' ? this.data_user.features?.blogger_labels?.data?.slice(0, 6) || [] : this.data_user.features?.blogger_thematics?.data?.slice(0, 5) || [];

                const categories = [];

                IDs.forEach(category => {
                  const exists = this.local_data.categories.all_categories.find((cat => cat.id === category));
                  if (exists) {
                    categories.push(exists.title);
                  }
                });

                this.local_data = {
                  ...this.local_data, 
                  categories: {
                    all_categories: categories // Solo los títulos filtrados
                  }
                };
              }
            }

            this.mentions_user = !Array.isArray(this.data_user.features.blogger_mentions) ? this.data_user.features.blogger_mentions.data.mentions.performance["180d"] : null;
            if (this.mentions_user) {
              for (const user in this.mentions_user.users) {
                this.mentions_avatares.push(this.mentions_user.users[user].avatar_url)
              }
              this.mentions_avatares = this.mentions_avatares.slice(0, this.data_user.features.blogger_mentions.data.reports.length)
            }
          } else if (this.network === 'twitch' || this.network === 'twitter') {
            this.data_user = response.report;
            this.is_paid = response.is_paid
          }


          // to get data from parent "searcher" component
          this.data_user.parent_data = this.local_data;
          this.loaded_service = true;
        })
      }

      this.fetchSubscribersHistory(username)

      this.getInfluenerInLists(username);
    },
    getInfluenerInLists(username) {
      getInfluencerIn(username, this.network , 'lists').then((response) => {
        this.lists = response.results.filter(list => {
          if (!list.is_favorite) return list;
          else this.favorite_list = list;
        })
      })
    },
    // To get data from graph growth of subscribers
    fetchSubscribersHistory(username) {
      const fetchFunction = this.network === "instagram" ? getHistorySuscribers : getHistorySuscribersLaravel;
      const metric_key = this.network === "instagram" ? "followers_count" : "subscribers_count";

      fetchFunction({ network: this.network, username })
        .then((response) => {
          if (response && Array.isArray(response.metrics) && response.metrics[metric_key]) {
            this.subscribers_growth_prc_graph = response.metrics[metric_key].history.map(item => ({
              count: item.value,
              date: Math.floor(new Date(item.time_iso).getTime() / 1000)
            }));
          }
        });
    },

    updateFavoriteList(status, user_added = null) {
      this.favorite_list.with_influencer = (status === 'added');
      if (user_added) {
        this.favorite_list.list_element_uuid = user_added.uuid;
      }
    },
    unlockReport() {
      if (hasPermission('view_profile')) {
        const data = {
          username: this.data.username.name.username,
          network: this.network
        }

        unlockReport(data).then((response) => {
          if (response.processing) {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: 'Obteniendo métricas',
                  text: 'Estamos trabajando para obtener toda la información posible del perfil, puedes seguir navegando y te avisaremos cuando este lista',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                }
              })
            })
          } else {
            this.$toast.clear();
            this.$router.push({name: 'auth-perfil', params: {'alias': this.data.username.name.username, 'network': this.network}})
          }
        })
      }
    },
    getProfileUrl() {
      if (hasPermission('view_profile')) {
        // TODO: Desbloquear perfil
        const alias = this.data.username.name.username;
        this.$router.push({name: 'auth-perfil', params: {network: this.network, alias}})
      }
    },
    errorLoad(content) {
      content.basic.preview_url = this.backgroundDefault;
      this.update_image = !this.update_image;
      return this.backgroundDefault;
    },
    hasClosedSidebar() {
      this.$refs.sidebar_search.$el.classList.remove('open-sidebar-search')
    },
  },
};
</script>
<style scoped lang="scss">
.overflow-none {
  height: auto;
  min-height: 100%;
}
.spinner-dropdown-item {
  position: absolute;
  right: 19px;
  background-color: white;
  height: 16px;
  width: 16px;
  color: #ccc;
}
.text-muted-left-side-analytics {
  font-size: 14px;
  font-family: 'avenir-medium';
  color: #718096;
  margin-bottom: 0.3em;
}
.border-bottom-sidebar {
  position: relative;
  height: fit-content;
  top: 15px;
}
.background-small {
  background-color: #F5F8FA;
  overflow-y: scroll;
  border-left: 1px solid #e2e8f0;
}
.text-underline {
  text-decoration: underline;
}

.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  top: 0px;
  left: 0px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  .grid-item {
    &:nth-child(1) {
      grid-column-start:1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
      
      .img-cover {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        height: 281px;
      }
    }
    &:nth-child(3) {
      .img-cover {
        border-top-right-radius: 6px;
      }
    }
    &:nth-child(5) {
      .img-cover {
        border-bottom-right-radius: 6px;
      }
    }
  }
}
.mr-05 {
  margin-right: 0.2em;
}
.mb-02 {
  margin-bottom: 0.4em;
}
.min-height-281 {
  min-height: 281px;
}
.min-height-116 {
  min-height: 116px;
}
.min-height-321 {
  height: 321px;
  min-height: 321px;
}
.min-height-232 {
  height: 232px;
  min-height: 232px;
}
.box-expired-report {
  background-color: white;
  padding: 36px 0px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box-style-analytics {
  border-radius: 6px;
  box-shadow: 0 1px 4px #0b14260a;
  padding: 16px;
  background-color: white;
  border: 1px solid #E2E8F0;
  .grid-inner-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .inner-box {
      border: 1px solid #E2E8F0;
      border-radius: 6px;
      box-shadow: 0 1px 4px #0b14260a;
      padding: 16px;
    }
  }
  .grid-more-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4);
    gap: 10px;

  }
}

.grid-analytics {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

}
.icon-coin {
  width: 20px;
  height: 20px;
}
.block-emtpy-data {
  background-color: #eff3f6;
  border-radius: 6px;
  border: 1px solid #f0f0f0;
  .container-icon-lock {
    background-color: #0096fe;
    padding: 1em;
    overflow: hidden;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;

  }
  .icon-lock {
    color: white;
    width: 30px;
    height: 30px;
  }
}


.backgroud-gray-light-avatar {
  background-color: #eff3f6;
  color: #687385;
}
.f-16 {
  font-size: 16px;
}
</style>
<style lang="scss">
.sidebar-search-new-version > .b-sidebar {
  width: 910px !important;
}
.avatar-sidebar-search {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 100%;
}
.sidebar-search-new-version .bg-light{
  background-color: white !important;
}
.sidebar-search-new-version .b-sidebar-header {
  padding: 1em 0;
  font-size: 13px !important;
  border-bottom: 1px solid #e2e8f0;
}
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.container-thubnail {
  position: relative;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 1em;
}
.thubnail-contents-sidebar {
  width: 280px;
  height: 280px;
  object-fit: cover;
  border-radius: 1em !important;
  display: block;
}
.container-thubnail:hover {
  cursor: pointer;
}
.container-thubnail:hover .container-text-sidebar {
  animation: animation-text 400ms;
  display: block;
}
.container-reations-sidebar {
  background-color: white;
  height: 50px;
  width: 280px;
  position: absolute;
  bottom: 0em;
  border-radius: 0.6em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 0 0.5em;
}
.container-text-sidebar {
  background: rgb(0, 0, 0, 0.5);
  color: white;
  position: absolute;
  top: 0;
  height: 240px;
  padding: 1em;
  width: 280px;
  border-radius: 1em;
  display: none;
  overflow: hidden;
  font-size: 13px;
}
.padding-right-01 {
  padding-right: 0.2em;
}
.spinner-searcher-sidebar {
  width: 4em;
  height: 4em;
  color: gray;
  margin: 15em 0 0 0;
}
.margin-category-badge {
  margin: 0.3em 0.3em 0.3em 0;
}
.container-drop-items {
  // width: 250px;
  width: 100%;
}
.container-drop-items > a:hover {
  background-color: #ccc !important;
}
.outline-blue-hover .btn-group {
  border: none !important;
}
.outline-blue-hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid #0096fe;
  color: #0096fe;
  padding: 11px 21px;
  border-radius: 0.5em;
}
.outline-blue-hover:hover {
  background-color: #0096fe;
  color: white;
  transition: all 300ms;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dropdown-color > button {
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

@keyframes animation-text {
  0% {
    transform: translateY(300px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
